<template>
  <div>
    <template>
      <div class="flex flex-end">
        <!--v-show="curTypeIndex === 0"-->
        <home-left-menu
          :list="subList"
          ref="homeChildMenu"
          v-if="curTypeIndex === 0 && $g.isDepart"
          @showCom="showComHandle"
          class="wd50"
        />
        <list-menu
          :list="getChildList"
          ref="homeChildMenu"
          v-else-if="$g.treeDefinate && noCollectAndGroup"
          @showCom="showComNewYunNanHandle"
        />
      </div>

      <a-tree
        v-if="cameralList.length > 0"
        :load-data="onLoadCameraSelectData"
        :tree-data="cameralList"
        :expandedKeys.sync="expandedTreeKeys"
        @select="treeNodeSelect"
      >
        <template
          slot="customtitle"
          slot-scope="{ title, online, total, type, onlineStatus, check }"
        >
          <template>
            <span v-if="type === `camera`" class="flex items-center">
              <span
                :class="{
                  'm-r-xs icon-style': true,
                  online: onlineStatus === '1',
                  offline: onlineStatus === '0',
                  error: onlineStatus === '2',
                }"
              ></span
              >{{ title }}</span
            >
            <div
              :class="[
                'flex fl-bg',
                { 'not-cursor': cameraLoading, current: check },
              ]"
              v-else
            >
              <span class="m-r-xs">{{ title }}</span>
              <span class="f12">
                (<span class="text-lightblue">{{ online }}</span
                ><span class="m-l-xs m-r-xs">/</span>{{ total }})
              </span>
            </div>
          </template>
        </template>
      </a-tree>
      <div v-if="loading" class="full-width flex items-center justify-center">
        <a-spin />
      </div>
      <div
        class="text-lightblue text-center p-h-sm"
        v-if="cameralList.length <= 0 && !loading"
      >
        空空如也~
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
// import { treeData } from '@/tools';
import { queryStaticDataList } from '@/tools';
import HomeLeftMenu from '@/components/LeftMenu';
import ListMenu from '@/components/ListMenu';
import {
  treeOrganiztionCheckNoCamera,
  treeDataReCheckNoCamera,
  treeDataReCheck,
  treeTrackCameraMulCheck,
  createGuid,
} from '@/tools';
export default {
  name: 'TreeList',
  props: {
    curTypeIndex: {
      type: Number,
      default: -1,
    },
    curItem: {
      type: Object,
      default() {
        return {};
      },
    },
    noCollectAndGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newChildList: {
        0: [
          {
            id: 0,
            name: '单位',
            type: 'organization',
          },
          {
            id: 1,
            name: '区域',
            type: 'province',
          },
          {
            id: 2,
            name: '路线',
            type: 'road',
          },
        ],
        1: [
          {
            id: 3,
            name: '轨道交通',
            type: 'railTraffic',
          },
          {
            id: 4,
            name: '客运站',
            type: 'noRoadPa',
          },
          {
            id: 5,
            name: '港口码头',
            type: 'noRoadPa',
          },
          {
            id: 6,
            name: '口岸',
            type: 'noRoadPa',
          },
          {
            id: 7,
            name: '公交',
            type: 'noRoadPa',
          },
          {
            id: 8,
            name: '邮政',
            type: 'noRoadPa',
          },
          {
            id: 9,
            name: '铁路',
            type: 'noRoadPa',
          },
          {
            id: 10,
            name: '民航',
            type: 'noRoadPa',
          },
        ],
      },
      parentList: [
        {
          id: 0,
          name: '公路',
          type: 'roadPa',
        },
        {
          id: 1,
          name: '客运站',
          type: 'noRoadPa',
        },
        {
          id: 2,
          name: '轨道交通',
          type: 'noRoadPa',
        },
        {
          id: 3,
          name: '港口码头',
          type: 'noRoadPa',
        },
        {
          id: 4,
          name: '口岸',
          type: 'noRoadPa',
        },
      ],
      subYuanNanList: [
        {
          id: 2,
          name: '单位',
          type: 'organization',
        },
        {
          id: 1,
          name: '区域',
          type: 'province',
        },
        {
          id: 0,
          name: '路线',
          type: 'road',
        },
      ],
      subList: [
        {
          id: 0,
          name: '路线',
          type: 'road',
          sort1: 3,
          sort2: 3,
        },
        {
          id: 1,
          name: '区域',
          type: 'province',
          sort1: 1,
          sort2: 2,
        },
        {
          id: 2,
          name: '单位',
          type: 'organization',
          sort1: 2,
          sort2: 1,
        },
      ],
      curCom: 0,
      cameraTreeDataPath: {
        organization: ['province', 'organization', 'road'],
        road: ['road', 'province'],
        province: ['province', 'road'],
      },
      type: 'road',
      loading: false,
      lastKey: '',
      obj: {
        currPage: 1,
        pageSize: 10,
      },
      curParent: 0,
      curPaType: 'roadPa',
      expandedTreeKeys: [],
      //新增
      curChildItem: {
        id: 0,
        name: '单位',
        type: 'organization',
      },
    };
  },
  components: {
    HomeLeftMenu,
    ListMenu,
  },
  computed: {
    ...mapState({
      classify: (state) => state.home.classify,
      amapIns: (state) => state.home.amapIns,
      cameralList: (state) => state.home.cameralList,
      cameraLoading: (state) => state.collect.cameraLoading,
    }),
    getChildList() {
      return this.newChildList[this.curTypeIndex];
    },
  },
  watch: {
    curItem: {
      handler(nv, ov) {
        if (nv !== ov) {
          if (this.$g.treeDefinate) {
            this.curChildItem =
              this.curItem.id === 0
                ? {
                    id: 0,
                    name: '单位',
                    type: 'organization',
                  }
                : {
                    id: 3,
                    name: '轨道交通',
                    type: 'railTraffic',
                  };
            this.curPaType = this.curItem.id === 0 ? 'roadPa' : '';
            this.showComNewYunNanHandle(this.curChildItem);
            this.$nextTick(() => {
              this.$refs.homeChildMenu.resetCurrentIndex(
                this.curItem.id === 0 ? 0 : 3
              );
            });
          } /* else {
            this.showParentComHandle(this.curItem);
          } */
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.$g.isDepart) {
      this.subList = this.subList.sort((a, b) => a.sort1 - b.sort1);
      this.type = 'province';
      this.obj = {
        currPage: 1,
        pageSize: 10,
      };
    } else {
      this.subList = this.subList.sort((a, b) => a.sort2 - b.sort2);
      this.type = 'organization';
      this.obj = {
        currPage: 0,
        pageSize: 0,
      };
    }
  },
  mounted() {
    if (this.$g.treeDefinate) {
      // this.getYunOrganizationCameral();
      // console.log(this.curItem);
      // this.showParentComHandle(this.curItem);fixsxy
      this.showComNewYunNanHandle(this.curChildItem);
    } else {
      // this.selectData(this.type);
      if (this.$g.isDepart) {
        // this.getProvinceCameral('province');
        this.getProvinceDepart();
      } else {
        this.selectData(this.type);
      }
    }
  },
  methods: {
    ...mapMutations([
      'setCameralList',
      'setChartData',
      'setSelectNode',
      'setComType',
      'setCameraAllList',
      'setTreeStru',
    ]),
    ...mapActions(['getCameralList', 'getCameraListByCondition']),
    showParentComHandle(item) {
      this.curParent = item.id;
      this.curPaType = item.type; //fixsxy
      if (this.curParent === 0) {
        this.currentType = 'organization';
        this.$refs.homeChildMenu.resetCurrentIndex();
        this.selectYunData(this.currentType);
      } else if (this.curParent === 3) {
        this.getYunParentData('9901004074');
      } else if (this.curParent === 4) {
        this.getYunParentData('9901004075');
      } else if (this.curParent === 5) {
        this.getYunParentData('9901004076');
      } else if (this.curParent === 6) {
        this.getYunParentData('9901004077');
      }
    },
    showComYunNanHandle(item) {
      let type = item.type;
      this.currentType = type;
      //切换层级
      /* this.gradeType = 1; */
      this.selectYunData(type);
    },
    selectYunData(type) {
      switch (type) {
        case 'road':
          this.getYunRoadCameral();
          break;
        case 'province':
          this.getYunProvinceCameral();
          break;
        case 'organization':
          this.getYunOrganizationCameral();
          break;
      }
    },
    getYunParentData(organizationId) {
      let data = {
        organizationId,
      };
      this.loading = true;
      this.setCameralList([]);
      this.$api.aiCenterY.cameraTreeAccessOffHighway(data).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          // console.error('----------', treeOrganiztionCheck(res.data));
          this.setCameralList(treeOrganiztionCheckNoCamera(res.data, true));
          // this.setChartInfoData(treeOrganiztionCheck(res.data));
        }
      });
    },
    getYunOrganizationCameral() {
      let data = {
        regionCode: this.$g.regionCode,
        yn: true,
      };
      this.loading = true;
      this.setCameralList([]);
      this.$api.home.cameraTreeByOrg(data).then((res) => {
        if (res.code === 200) {
          // console.error(treeDataReCheck(res.data, 'yunOrganization'));
          this.loading = false;
          let formatData = treeOrganiztionCheckNoCamera(res.data);
          this.setCameralList(formatData);
          // this.setChartInfoData(formatData);
        }
      });
    },
    getYunProvinceCameral() {
      let data = {
        gradeType: 1,
        params: {
          regionCode: this.$g.regionCode,
        },
      };
      this.loading = true;
      this.setCameralList([]);
      this.$api.home.cameraTreeAccessByRegion(data).then((data) => {
        if (data.code === 200) {
          let arr = data.data.info.filter(() => {
            // debugger;
            //return item.regionCode != this.$g.regionCode;
            return true;
          });
          /*   this.gradeType = data.data.hierarchy; */
          this.loading = false;
          let formatData = [];
          if (this.$g.treeDefinate) {
            formatData = treeDataReCheck(arr, 'provinceYun');
          } else {
            formatData = treeDataReCheck(arr, 'province');
          }
          // let formatData = treeDataReCheck(arr, 'province');
          this.setCameralList(formatData);
          // this.setChartInfoData(formatData);
          // console.error(treeDataReCheck(arr, 'province'));
        }
      });
    },
    getYunRoadCameral() {
      let data = {
        gradeType: 1,
        params: {
          regionCode: this.$g.regionCode,
        },
      };
      this.loading = true;
      this.setCameralList([]);
      this.$api.home.cameraTreeAccessByRoad(data).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          let formatData = treeDataReCheckNoCamera(res.data.info, 'road');
          // console.error('ha----', formatData);
          this.setCameralList(formatData);
          // this.setChartRoadInfoData(formatData);
          // console.error(treeDataReCheck(res.data.info, 'road'));
        }
      });
    },
    /**
     * 查询tree下级数据
     * @param treeNode
     * @returns {Promise<unknown>}
     */
    selectData(type) {
      switch (type) {
        case 'road':
          // this.getRoadCameral(type);
          if (this.$g.isDepart) {
            this.getRoadStatis();
          } else {
            this.getRoadCameral(type);
          }
          break;
        case 'province':
          // this.getProvinceCameral(type);
          if (this.$g.isDepart) {
            this.getProvinceDepart();
          } else {
            this.getProvinceCameral(type);
          }
          break;
        case 'organization':
          this.getOrganizationCameral(type);
          break;
      }
    },
    getRoadStatis() {
      let data = {};
      this.loading = true;
      this.setCameralList([]);
      this.$api.resource.getByRoad(data).then((res) => {
        if (res.code) {
          this.loading = false;
          let arr = this._getNormalStatic(res.data);
          this.setCameralList(arr);
        }
      });
    },
    _getNormalStatic(data) {
      let keys = Object.keys(data);
      let arr = [];
      for (let i = 0; i < keys.length; i++) {
        let obj = {
          title: keys[i],
          key: `${i}-${createGuid()}`,
          isLeaf: false,
          type: 'roadBefore',
        };
        let child = data[keys[i]].map((item) => {
          item.key = `${item.roadId}-${createGuid()}`;
          item.isLeaf = false;
          item.scopedSlots = { title: 'customtitle' };
          item.title = item.name;
          item.baseId = item.roadId;
          item.id = item.roadId;
          item.type = 'road';
          item.needType = 'roadArea';
          return item;
        });
        obj.children = child;
        arr.push(obj);
      }
      return arr;
    },
    getProvinceDepart() {
      let params = {
        type: 1,
      };

      this.loading = true;
      this.setCameralList([]);
      this.$api.home.getCameraByRegion(params).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          let data = res.data.map((item) => ({
            ...item,
            title: item.regionName,
            gradeType: params.type + 1,
            isLeaf: false,
            check: false,
            key: createGuid(),
            type: 'province',
            needType: 'region',
            scopedSlots: { title: 'customtitle' },
            id: item.regionCode,
          }));
          this.setCameralList(data);
        }
      });
    },
    getRoadCameral(type) {
      let data = {
        provinceCode: '',
        regionCode: '',
        road: '',
        classify: this.classify,
      };
      let params = {
        dimensionType: type,
        data: data,
      };
      this.loading = true;
      /* this.cameralList = [];
      queryStaticDataList(params, (res) => {
        this.loading = false;
        this.cameralList = res.data;
      }); */
      this.setCameralList([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        this.setCameralList(res.data);
        // this.setChartRoadInfoData(res.data);
      });
    },
    getProvinceCameral(type) {
      let data = {
        cityCode: '',
        provinceCode: 410000,
        regionCode: 410000,
        road: '',
        classify: this.classify,
      };
      let params = {
        dimensionType: type,
        data: data,
      };
      this.loading = true;
      // this.cameralList = [];
      this.setCameralList([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        this.setCameralList(res.data);
        // this.setChartInfoData(res.data);
      });
    },
    // eslint-disable-next-line no-unused-vars
    getOrganizationCameral(type) {
      let data = {
        organizationId: '',
        provinceCode: 410000,
        regionCode: 410000,
        road: '',
        classify: this.classify,
      };
      let params = {
        dimensionType: 'province',
        data: data,
      };
      this.loading = true;
      /* this.cameralList = [];
      queryStaticDataList(params, (res) => {
        this.loading = false;
        this.cameralList = res.data;
      }); */
      this.setCameralList([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        res.data = res.data.map((item) => {
          item.needType = 'region';
          item.regionCode = item.id;
          return item;
        });
        this.setCameralList(res.data);
        // this.setChartInfoData(res.data);
      });
    },
    onLoadCameraSelectData(treeNode) {
      // let expandData = this.treeNodeExpandPathDataCheck(treeNode);
      let vm = this;
      return new Promise((resolve) => {
        let type = treeNode.dataRef.type;
        //已有子节点，不重复加载
        if (type === 'roadBefore') {
          resolve();
          return;
        }
        if (!this.$g.treeDefinate) {
          if (this.$g.isDepart) {
            if (this.type === 'organization') {
              let expandData = this.treeNodeExpandPathDataCheck(treeNode);
              vm.getCameraSelectTreeData(type, expandData, (data) => {
                for (var i = 0, lens = data.length; i < lens; i++) {
                  data[i].provinceId = expandData;
                }

                treeNode.dataRef.children = data;
                if (type === 'road') {
                  let selectTreeRoadData = [...vm.cameralList];
                  // this.$emit('showData', selectTreeRoadData);
                  this.setCameralList(selectTreeRoadData);
                }
                if (type === 'organization') {
                  let dataFormat = data.map((item) => {
                    if (item.type === 'organization') {
                      item.needType = 'organizationArea';

                      item.organizationId = item.id;
                    } else if (item.type === 'roadBefore') {
                      item.children.map((child) => {
                        child.needType = 'roadArea';
                        child.roadId = child.roadCode;
                        (child.organizationId = expandData.organizationId),
                          (child.regionCode = expandData.regionCode);
                        return child;
                      });
                    }
                    return item;
                  });
                  treeNode.dataRef.children = dataFormat;
                  let selectTreeOrgData = [...vm.cameralList];
                  // this.$emit('showData', selectTreeOrgData);
                  this.setCameralList(selectTreeOrgData);
                }
                if (type === 'province') {
                  let dataFormat = data.map((item) => {
                    if (item.type === 'organization') {
                      item.needType = 'organizationArea';

                      item.organizationId = item.id;
                    } else if (item.type === 'roadBefore') {
                      item.children.map((child) => {
                        child.needType = 'roadArea';
                        child.roadId = child.id;
                        return child;
                      });
                    }
                    return item;
                  });
                  treeNode.dataRef.children = dataFormat;
                  let selectTreeRegionData = [...vm.cameralList];
                  // this.$emit('showData', selectTreeRegionData);
                  this.setCameralList(selectTreeRegionData);
                }
                resolve();
              });
            } else if (this.type === 'province') {
              let regionCode = treeNode.dataRef.regionCode;
              let gradeType = treeNode.dataRef.gradeType;
              let regionCodes = treeNode.dataRef.regionCodes;
              if (gradeType < 4) {
                let params = {
                  type: gradeType,
                  regionCode,
                  regionCodes,
                };
                this.$api.home.getCameraByRegion(params).then((res) => {
                  if (res.code === 200) {
                    treeNode.dataRef.children = res.data.map((item) => {
                      if (gradeType === 2) {
                        item.title = item.regionNames;
                        item.type = 'province';
                        item.needType = 'regions';
                        item.id = item.regionCodes;
                      } else if (gradeType === 3) {
                        item.title = item.roadName;
                        item.needType = 'roadArea';
                        (item.type = 'road'), (item.id = item.roadId);
                      }
                      item.gradeType = params.type + 1;
                      item.isLeaf = item.gradeType < 4 ? false : true;
                      item.scopedSlots = { title: 'customtitle' };
                      item.check = false;
                      item.key = createGuid();
                      return item;
                    });
                    let selectTreeRoadData = [...vm.cameralList];
                    this.setCameralList(selectTreeRoadData);
                    resolve();
                  }
                });
              }
            } else if (type === 'road') {
              let roadId = treeNode.dataRef.roadId;
              this.clickRoads(roadId, treeNode, resolve);
            }
          } else {
            let expandData = this.treeNodeExpandPathDataCheck(treeNode);
            vm.getCameraSelectTreeData(type, expandData, (data) => {
              for (var i = 0, lens = data.length; i < lens; i++) {
                data[i].provinceId = expandData;
              }

              treeNode.dataRef.children = data;
              if (type === 'road') {
                let selectTreeRoadData = [...vm.cameralList];
                // this.$emit('showData', selectTreeRoadData);
                this.setCameralList(selectTreeRoadData);
              }
              if (type === 'organization') {
                let selectTreeOrgData = [...vm.cameralList];
                // this.$emit('showData', selectTreeOrgData);
                this.setCameralList(selectTreeOrgData);
              }
              if (type === 'province') {
                let selectTreeRegionData = [...vm.cameralList];
                // this.$emit('showData', selectTreeRegionData);
                this.setCameralList(selectTreeRegionData);
              }
              resolve();
            });
          }
        } else {
          let roadId = treeNode.dataRef.roadId;
          if (this.type === 'road') {
            /*let data = {
              gradeType: 2,
              params: {
                regionCode: this.$g.regionCode,
                roadId,
              },
            };
             this.$api.home.cameraTreeAccessByRoad(data).then((res) => {
              if (res.code === 200) {
                treeNode.dataRef.children = treeDataReCheck(
                  res.data.info,
                  'camera'
                );
                let selectTreeRoadData = [...vm.cameralList];
                this.setCameralListTree(selectTreeRoadData);
                resolve();
              }
            }); */
          } else if (this.type === 'province') {
            /*  let gradeType =
              this.gradeType === 3 ? this.gradeType : this.gradeType + 1; */

            let regionCode = treeNode.dataRef.regionCode;
            let data = {
              gradeType: 2,
              params: {
                regionCode,
                roadId: roadId,
              },
            };
            this.$api.home.cameraTreeAccessByRegion(data).then((data) => {
              if (data.code === 200) {
                /*  this.gradeType = data.data.hierarchy; */
                treeNode.dataRef.children = treeDataReCheck(
                  data.data.info,
                  'provinceRoadNoCamera'
                );
                let selectTreeRoadData = [...vm.cameralList];
                this.setCameralList(selectTreeRoadData);
                resolve();
              }
            });
          }
        }
      });
    },
    clickRoads(roadId, treeNode, resolve) {
      let data = {
        roadId,
      };
      /* let roadListCopy = this.roadLists.slice(); */

      this.$api.resource.getByProvince(data).then((res) => {
        if (res.code === 200) {
          let arr = res.data.map((item) => {
            item.key = `provice-${createGuid()}`;
            item.title = item.name;
            item.scopedSlots = { title: 'customtitle' };
            item.isLeaf = true;
            item.specialType = 'road';
            item.baseId = item.regionCode;
            item.id = item.regionCode;
            item.type = 'province';
            item.needType = 'region';
            return item;
          });
          treeNode.dataRef.children = arr;
          let selectTreeRoadData = [...this.cameralList];
          this.setCameralList(selectTreeRoadData);
          resolve();
        }
      });
    },
    getCameraSelectTreeData(currtype, expandData, callback) {
      let childrenData = [],
        type = '';
      let path = this.cameraTreeDataPath[this.type];
      type = path[path.indexOf(currtype) + 1];
      let idx = path.indexOf(currtype) + 1;
      if (type === 'road' && expandData.provinceCode) {
        expandData.groupbyRoadType = false;
      }
      this.lastKey = type;
      queryStaticDataList(
        {
          dimensionType: type,
          data: expandData,
        },
        (res) => {
          childrenData = res.data;

          if (
            (this.type === 'road' && idx === 1) ||
            (this.type === 'organization' && idx === 2) ||
            (this.type === 'province' && idx === 1)
          ) {
            childrenData = childrenData.map((item) => {
              if (item.children && item.children.length > 0) {
                item.children = item.children.map((it) => {
                  it.isLeaf = true;
                  return it;
                });
              } else {
                item.isLeaf = true;
              }

              return item;
            });
          }

          if (typeof callback === 'function') {
            callback(childrenData);
          }
        }
      );
    },
    /**
     * 查询节点父节点信息
     * @param node
     */
    treeNodeExpandPathDataCheck(node) {
      let path = this.cameraTreeDataPath[this.type],
        newKsys = {};
      let getParentKeyArray = (node) => {
        let keys = [],
          newNode = node;
        for (let i = 0; i < 10; i++) {
          let dataref = newNode.$parent.dataRef;
          if (dataref) {
            newNode = newNode.$parent;
            //console.info('getParent ', dataref);
            if (dataref.type !== 'roadBefore') keys.push(dataref.baseId);
          }
        }
        // 倒序
        return keys.reverse();
      };
      let pathValAr = getParentKeyArray(node);
      if (node.dataRef.type !== 'roadBefore')
        pathValAr.push(node.dataRef.baseId);
      path.forEach((vo, idx) => {
        let nval = pathValAr[idx];
        if (!nval) {
          return;
        }
        switch (vo) {
          case 'province':
            newKsys.provinceCode = parseInt(nval);
            newKsys.regionCode = parseInt(nval);
            // newKsys.organizationType = '10';fix sxy
            break;
          case 'city':
            newKsys.cityCode = parseInt(nval);
            break;
          case 'organization':
            newKsys.organizationType = '20';
            newKsys.organizationId = nval;
            break;
          case 'road':
            newKsys.organizationType = '20';
            newKsys.road = nval;
            break;
        }
      });
      newKsys.classify = this.classify;
      return newKsys;
    },
    /**
     * 树节点点击
     * @param selectedKeys
     * @param e
     */
    treeNodeSelect(selectedKeys, e) {
      if (this.cameraLoading) {
        return;
      }
      //设置点击展开收缩节点
      let selectedKey = e.node.dataRef.key;
      if (!e.node.expanded) {
        this.expandedTreeKeys = [...this.expandedTreeKeys, selectedKey];
      } else {
        this.expandedTreeKeys = this.expandedTreeKeys.filter(
          (item) => item !== selectedKey
        );
      }
      //设置check状态

      let copyCamera = JSON.parse(JSON.stringify(this.cameralList));
      e.node.dataRef.check = e.node.dataRef.check ? false : true;
      let cameraList = treeTrackCameraMulCheck(copyCamera, e.node.dataRef);

      this.setCameralList(cameraList);
      let dataRef = e.node.dataRef;
      if (
        dataRef.type !== 'camera' &&
        dataRef.type !== 'provinceRoadNoCamera'
      ) {
        this.onLoadCameraSelectData(e.node);
      }
      this.$emit('getCamera', dataRef);
      /* let leafType = dataRef.type;
      let newObj = {};
      if (!this.$g.treeDefinate) {
        newObj = { ...this.obj, ...this.extraObj(leafType, dataRef) };
      } else {
        newObj = { ...this.obj, ...this.extraObj(this.type, dataRef) };
      }
      this.obj = newObj;
      this.setTreeStru(this.obj);
      if (!this.$g.treeDefinate) {
        if (dataRef.type !== 'camera' && dataRef.type !== 'roadBefore') {
          this.setCameraAllList([]);
          this.getCameraListByCondition(newObj);
        }
      } else {
        if (dataRef.type !== 'camera') {
          this.setCameraAllList([]);
          this.getCameraListByCondition(newObj);
        }
      } */
    },
    extraObj(leafType, dataRef) {
      let obj = {};
      if (!this.$g.treeDefinate) {
        if (leafType === 'road') {
          obj.roadCode = dataRef.id;
        } else if (leafType === 'province') {
          obj.regionId = dataRef.id;
        } else if (leafType === 'organization') {
          obj.organizationId = dataRef.id;
        }
        /* if (!this.$g.isDepart) {
          if (leafType === 'road') {
            obj.roadCode = dataRef.id;
          } else if (leafType === 'province') {
            obj.regionId = dataRef.id;
          } else if (leafType === 'organization') {
            obj.organizationId = dataRef.id;
          }
        } else {
          if (leafType === 'road') {
            obj.roadCode = dataRef.id;
          } else if (leafType === 'province') {
            obj.regionId = dataRef.id;
          } else if (leafType === 'organization') {
            obj.organizationId = dataRef.id;
          }
        } */
      } else {
        if (leafType === 'road') {
          obj.roadCode = dataRef.roadId;
        } else if (leafType === 'province') {
          obj.regionId = dataRef.regionCode;
          obj.roadCode = dataRef.roadId;
        } else if (leafType === 'organization') {
          obj.organizationId = dataRef.organizationId;
        }
      }
      return obj;
    },
    handlePlay(title) {
      console.error(title);
    },
    showComNewYunNanHandle(item) {
      let type = item.type;
      this.type = type;
      this.currentCom = item.id;
      this.curChildItem = item; //新增
      this.obj = {
        currPage: 1,
        pageSize: 10,
      };
      if (this.$g.treeDefinate) {
        if (item.id === 0 || item.id === 1 || item.id === 2) {
          this.currentOrganizationId = '';
          this.selectYunData(type);
        } else if (item.id === 3) {
          this.currentType = '';
          this.currentOrganizationId = '9901004075';
          this.getYunParentData('9901004075');
        } else if (item.id === 4) {
          this.currentType = '';
          this.currentOrganizationId = '9901004074';
          this.getYunParentData('9901004074');
        } else if (item.id === 5) {
          this.currentType = '';
          this.currentOrganizationId = '9901004076';
          this.getYunParentData('9901004076');
        } else if (item.id === 6) {
          this.currentType = '';
          this.currentOrganizationId = '9901004077';
          this.getYunParentData('9901004077');
        }else if (item.id === 6) {
        this.currentType = '';
        this.currentOrganizationId = '9901004077';
        this.getYunParentData('9901004077');
      } else if (item.id === 7) {
        this.currentType = '';
        this.currentOrganizationId = '9901004117';
        this.getYunParentData('9901004117');
      } else if (item.id === 8) {
        this.currentType = '';
        this.currentOrganizationId = '9901004116';
        this.getYunParentData('9901004116');
      } else if (item.id === 9) {
        this.currentType = '';
        this.currentOrganizationId = '9901004118';
        this.getYunParentData('9901004118');
      } else if (item.id === 10) {
        this.currentType = '';
        this.currentOrganizationId = '9901004119';
        this.getYunParentData('9901004119');
      } else {
          this.currentType = '';
          this.currentOrganizationId = '0000'; //不存在的organizationId
          this.setCameralList([]);
        }
      }
      this.$emit('getCamera', {
        needType: 'none',
        organizationId: this.currentOrganizationId,
      });
      this.expandedKeys = [];
    },
    showComHandle(item) {
      let type = item.type;
      this.type = type;
      this.currentCom = item.id;
      if (this.$g.isDepart) {
        this.obj = {
          currPage: 1,
          pageSize: 10,
        };
      } else {
        this.obj = {
          currPage: 0,
          pageSize: 0,
        };
      }

      // this.setClassify('');fixsxy
      // this.setComType(type);fixsxy
      if (this.$g.treeDefinate) {
        this.selectYunData(type);
      } else {
        this.selectData(type);
      }
      this.$emit('getCamera', { needType: 'none' });
      this.setTreeStru(null);
    },
  },
};
</script>

<style scoped>
.icon-style {
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  transform-origin: center center;
}
.online {
  background: #5cc189;
}
.offline {
  background: #a7b1be;
}
.error {
  background: #ef5844;
}
.wd60 {
  width: 60%;
}
.fl-bg.current {
  background: #424e65;
  padding: 0 10px;
}
</style>
