var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collect-com full-height",staticStyle:{"padding-bottom":"50px"}},_vm._l((_vm.homeFolderCameraFormat),function(folder){return _c('div',{key:folder.folderId,staticClass:"folder m-b-sm pointer"},[_c('div',{staticClass:"folder-hd flex justify-between items-center  f15 m-b-sm",on:{"click":function($event){return _vm.expandFolder(folder)}}},[_c('div',{staticClass:"text-white flex",attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.dragstartFolder($event, folder)},"dragend":_vm.dragendFolder}},[_c('span',{staticClass:"weight flex items-center flex-one white-space",attrs:{"title":folder.folderName}},[_c('img',{staticClass:"m-r-xs",attrs:{"src":require("../assets/resource/collect-folder.png"),"width":"20"}}),_vm._v(_vm._s(folder.folderName))]),_vm._v("("+_vm._s(folder.cameraNumber)+") ")])]),(folder.show && folder.cameraList && folder.cameraList.length)?_vm._l((folder.cameraList),function(item){return _c('div',{key:item.cameraId,staticClass:"flex items-center m-b-sm p-w-sm text-white",attrs:{"title":item.cameraNames}},[_c('span',{class:[
              'icon-style  m-r-xs',
              {
                online: item.cameraStatus === 1,
                offline: item.cameraStatus === 0,
                error: item.cameraStatus === 2,
                yunOffline: item.cameraStatus === 0 && _vm.$g.treeDefinate,
              } ]}),_c('span',{staticClass:"flex-one ellipsis pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectShot(item)}}},[_vm._v(_vm._s(item.cameraNames))])])}):_vm._e()],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }