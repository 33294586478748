<template>
  <div class="tree-node">
    <div class="flex flex-end">
      <home-left-menu
        :list="subList"
        ref="homeChildMenu"
        v-if="curTypeIndex === 0 && $g.isDepart"
        @showCom="showComHandle"
        class="wd50"
      />
      <list-menu
        :list="getChildList"
        ref="homeChildMenu"
        v-else-if="$g.treeDefinate"
        @showCom="showComNewYunNanHandle"
      />
    </div>

    <a-tree
      v-if="cameralList.length > 0"
      :load-data="onLoadCameraSelectData"
      :tree-data="cameralList"
      @select="treeNodeSelect"
      @expand="onExpand"
      :expandedKeys.sync="expandedTreeKeys"
    >
      <template
        slot="customtitle"
        slot-scope="{
          title,
          online,
          total,
          type,
          onlineStatus,
          khPile,
          poiName,
          derection,
          cameraName,
          check,
        }"
      >
        <template>
          <div
            v-if="type === `camera` && !$g.treeDefinate"
            class="flex items-center"
            style="width:240px;"
            :title="getTitle(khPile, poiName, derection)"
          >
            <span
              :class="{
                'm-r-xs icon-style': true,
                online: String(onlineStatus) === '1',
                offline: String(onlineStatus) === '0',
                error: String(onlineStatus) === '2',
              }"
            ></span>
            <span class="ellipsis full-width fl-bg" :class="{ current: check }">
              {{ title }} {{ poiName }}
              {{ derection | getDirectionArrow }}</span
            >
          </div>
          <div
            v-else-if="type === `camera` && $g.treeDefinate"
            class="flex items-center"
            style="width:200px;"
          >
            <span
              :class="{
                'm-r-xs icon-style': true,
                online: String(onlineStatus) === '1',
                yunOffline: String(onlineStatus) === '0',
                error: String(onlineStatus) === '2',
              }"
            ></span>
            <div style="width:180px;" class="fl-bg" :class="{ current: check }">
              <span class="ellipsis full-width block"> {{ khPile }}</span>
              <span
                class="ellipsis full-width f12 block ellipsis"
                :title="cameraName"
                >{{ cameraName }}</span
              >
            </div>
          </div>
          <div class="flex fl-bg" :class="{ current: check }" v-else>
            <span class="m-r-xs">{{ title }}</span>
            <span class="f12">
              (<span class="text-lightblue">{{ online }}</span
              ><span class="m-l-xs m-r-xs">/</span>{{ total }})
            </span>
          </div>
        </template>
      </template>
    </a-tree>
    <div v-if="loading" class="full-width flex items-center justify-center">
      <a-spin />
    </div>
    <div
      class="text-lightblue text-center p-h-sm"
      v-if="cameralList.length <= 0 && !loading"
    >
      空空如也~
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
// import { treeData } from '@/tools';
import {
  queryStaticDataList,
  DIRECTOINARROW,
  treeDataReCheck,
  treeOrganiztionCheckTree,
  treeOrganiztionCheckTreeOther,
  createGuid,
  treeTrackCameraMulCheck,
} from '@/tools';
import HomeLeftMenu from '@/components/LeftMenu';
import ListMenu from '@/components/ListMenu';
export default {
  name: 'TreeListMode',
  props: {
    curTypeIndex: {
      type: Number,
      default: -1,
    },
    curItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      expandedKeys: [],
      newChildList: {
        0: [
          {
            id: 0,
            name: '单位',
            type: 'organization',
          },
          {
            id: 1,
            name: '区域',
            type: 'province',
          },
          {
            id: 2,
            name: '路线',
            type: 'road',
          },
        ],
        1: [
          {
            id: 3,
            name: '轨道交通',
            type: 'railTraffic',
          },
          {
            id: 4,
            name: '客运站',
            type: 'noRoadPa',
          },
          {
            id: 5,
            name: '港口码头',
            type: 'noRoadPa',
          },
          {
            id: 6,
            name: '口岸',
            type: 'noRoadPa',
          },
          {
            id: 7,
            name: '公交',
            type: 'noRoadPa',
          },
          {
            id: 8,
            name: '邮政',
            type: 'noRoadPa',
          },
          {
            id: 9,
            name: '铁路',
            type: 'noRoadPa',
          },
          {
            id: 10,
            name: '民航',
            type: 'noRoadPa',
          },
        ],
      },
      subList: [
        {
          id: 0,
          name: '路线',
          type: 'road',
          sort1: 3,
          sort2: 3,
        },
        {
          id: 1,
          name: '区域',
          type: 'province',
          sort1: 1,
          sort2: 2,
        },
        {
          id: 2,
          name: '单位',
          type: 'organization',
          sort1: 2,
          sort2: 1,
        },
      ],
      curCom: 0,
      cameraTreeDataPath: {
        organization: ['province', 'organization', 'road', 'camera'],
        road: ['road', 'province', 'camera'],
        province: ['province', 'road', 'camera'],
      },
      type: 'road',
      loading: false,
      lastKey: '',
      obj: {
        currPage: 1,
        pageSize: 10,
      },
      curPaType: '',
      expandedTreeKeys: [],
      //新增
      curChildItem: {
        id: 0,
        name: '单位',
        type: 'organization',
      },
    };
  },
  components: {
    HomeLeftMenu,
    ListMenu,
  },
  computed: {
    ...mapState({
      classify: (state) => state.home.classify,
      amapIns: (state) => state.home.amapIns,
      cameralList: (state) => state.home.cameralList,
      screenShotSearchParams: (state) => state.resource.screenShotSearchParams,
      screenShotProvinceParams: (state) =>
        state.resource.screenShotProvinceParams,
      isProvince: (state) => state.resource.isProvince,
    }),
    getChildList() {
      return this.newChildList[this.curTypeIndex];
    },
  },
  watch: {
    curItem: {
      handler(nv, ov) {
        if (nv !== ov) {
          if (this.$g.treeDefinate) {
            this.curChildItem =
              this.curItem.id === 0
                ? {
                    id: 0,
                    name: '单位',
                    type: 'organization',
                  }
                : {
                    id: 3,
                    name: '轨道交通',
                    type: 'railTraffic',
                  };
            this.curPaType = this.curItem.id === 0 ? 'roadPa' : '';
            this.showComNewYunNanHandle(this.curChildItem);
            /*  this.$refs.homeChildMenu.resetCurrentIndex(
              this.curItem.id === 0 ? 0 : 3
            ); */
            this.$nextTick(() => {
              this.$refs.homeChildMenu.resetCurrentIndex(
                this.curItem.id === 0 ? 0 : 3
              );
            });
          } /* else {
            this.showParentComHandle(this.curItem);
          } */
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.$g.isDepart) {
      this.subList = this.subList.sort((a, b) => a.sort1 - b.sort1);
      this.type = 'province';
    } else {
      this.subList = this.subList.sort((a, b) => a.sort2 - b.sort2);
      this.type = 'organization';
    }
  },
  mounted() {
    if (this.$g.treeDefinate) {
      // this.getYunOrganizationCameral();
      // this.showParentComHandle(this.curItem);fixsxy
      this.showComNewYunNanHandle(this.curChildItem);
    } else {
      // this.selectData(this.type);fix原来
      if (this.$g.isDepart) {
        // this.getProvinceCameral('province');
        this.getProvinceDepart();
      } else {
        this.selectData(this.type);
      }
    }
  },
  methods: {
    ...mapMutations([
      'setCameralList',
      'setChartData',
      'setSelectNode',
      'setComType',
      'setCameraAllList',
      'setTreeStru',
      'setScreenShotSearchParams',
      'setScreenShotProvinceParams',
    ]),
    ...mapActions([
      'getCameralList',
      'getScreenShotInfo',
      'getProvinceScreenShotInfo',
    ]),
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
    },
    showParentComHandle(item) {
      this.curParent = item.id;
      this.curPaType = item.type; //fixsxy
      if (this.curParent === 0) {
        this.currentType = 'organization';
        this.$refs.homeChildMenu.resetCurrentIndex();
        this.selectYunData(this.currentType);
      } else if (this.curParent === 3) {
        this.getYunParentData('9901004074');
      } else if (this.curParent === 4) {
        this.getYunParentData('9901004075');
      } else if (this.curParent === 5) {
        this.getYunParentData('9901004076');
      } else if (this.curParent === 6) {
        this.getYunParentData('9901004077');
      }
    },
    getYunParentData(organizationId) {
      let data = {
        organizationId,
      };
      this.loading = true;
      this.setCameralList([]);
      this.$api.aiCenterY.cameraTreeAccessOffHighway(data).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          // console.error('----------', treeOrganiztionCheck(res.data));
          this.setCameralList(treeOrganiztionCheckTreeOther(res.data,organizationId));
          // this.setChartInfoData(treeOrganiztionCheck(res.data));
        }
      });
    },
    getProvinceDepart() {
      let params = {
        type: 1,
      };

      this.loading = true;
      this.setCameralList([]);
      this.$api.home.getCameraByRegion(params).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          let data = res.data.map((item) => ({
            ...item,
            title: item.regionName,
            gradeType: params.type + 1,
            isLeaf: false,
            check: false,
            key: createGuid(),
            needType: 'region',
            scopedSlots: { title: 'customtitle' },
          }));
          this.setCameralList(data);
        }
      });
    },
    selectYunData(type) {
      switch (type) {
        case 'road':
          this.getYunRoadCameral();
          break;
        case 'province':
          this.getYunProvinceCameral();
          break;
        case 'organization':
          this.getYunOrganizationCameral();
          break;
      }
    },
    getYunOrganizationCameral() {
      let data = {
        regionCode: this.$g.regionCode,
        yn: true,
      };
      this.loading = true;
      this.setCameralList([]);
      this.$api.home.cameraTreeByOrg(data).then((res) => {
        if (res.code === 200) {
          // console.error(treeDataReCheck(res.data, 'yunOrganization'));
          this.loading = false;
          // let formatData = treeDataReCheck(res.data, 'yunOrganization');
          let formatData = treeOrganiztionCheckTree(res.data, true);
          // console.error(formatData);
          this.setCameralList(formatData);
        }
      });
    },
    getYunProvinceCameral() {
      let data = {
        gradeType: 1,
        params: {
          regionCode: this.$g.regionCode,
        },
      };
      this.loading = true;
      this.setCameralList([]);
      this.$api.home.cameraTreeAccessByRegion(data).then((data) => {
        if (data.code === 200) {
          let arr = data.data.info.filter((item) => {
            // debugger;
            // return item.regionCode != this.$g.regionCode;
            return item;
          });
          /*   this.gradeType = data.data.hierarchy; */
          this.loading = false;
          let formatData = [];
          // let formatData = treeDataReCheck(arr, 'province');
          if (this.$g.treeDefinate) {
            formatData = treeDataReCheck(arr, 'provinceYun');
          } else {
            formatData = treeDataReCheck(arr, 'province');
          }
          this.setCameralList(formatData);

          // console.error(treeDataReCheck(arr, 'province'));
        }
      });
    },
    getYunRoadCameral() {
      let data = {
        gradeType: 1,
        params: {
          regionCode: this.$g.regionCode,
        },
      };
      this.loading = true;
      this.setCameralList([]);
      this.$api.home.cameraTreeAccessByRoad(data).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          let formatData = treeDataReCheck(res.data.info, 'road');
          // console.error('ha----', formatData);
          this.setCameralList(formatData);

          // console.error(treeDataReCheck(res.data.info, 'road'));
        }
      });
    },
    getTitle(khPile, poiName, derection) {
      return `桩号:${khPile},兴趣点名称:${poiName},方向:${DIRECTOINARROW[derection]}`;
    },
    /**
     * 查询tree下级数据
     * @param treeNode
     * @returns {Promise<unknown>}
     */
    selectData(type) {
      switch (type) {
        case 'road':
          // this.getRoadCameral(type);fix原先
          if (this.$g.isDepart) {
            this.getRoadStatis();
          } else {
            this.getRoadCameral(type);
          }
          break;
        case 'province':
          // this.getProvinceCameral(type);fix原先
          if (this.$g.isDepart) {
            this.getProvinceDepart();
          } else {
            this.getProvinceCameral(type);
          }
          break;
        case 'organization':
          this.getOrganizationCameral(type);
          break;
      }
    },
    getRoadStatis() {
      let data = {};
      this.loading = true;
      this.setCameralList([]);
      this.$api.resource.getByRoad(data).then((res) => {
        if (res.code) {
          this.loading = false;
          let arr = this._getNormalStatic(res.data);
          this.setCameralList(arr);
        }
      });
    },
    _getNormalStatic(data) {
      let keys = Object.keys(data);
      let arr = [];
      for (let i = 0; i < keys.length; i++) {
        let obj = {
          title: keys[i],
          key: `${i}-${createGuid()}`,
          isLeaf: false,
          type: 'roadBefore',
        };
        let child = data[keys[i]].map((item) => {
          item.key = `${item.roadId}-${createGuid()}`;
          item.isLeaf = false;
          item.scopedSlots = { title: 'customtitle' };
          item.title = item.name;
          item.baseId = item.roadId;
          item.needType = 'roadArea';
          return item;
        });
        obj.children = child;
        arr.push(obj);
      }

      return arr;
    },
    clickRoads(roadId, treeNode, resolve) {
      let data = {
        roadId,
      };
      /* let roadListCopy = this.roadLists.slice(); */

      this.$api.resource.getByProvince(data).then((res) => {
        if (res.code === 200) {
          let arr = res.data.map((item) => {
            item.key = `provice-${createGuid()}`;
            item.title = item.name;
            item.scopedSlots = { title: 'customtitle' };
            item.isLeaf = false;
            item.specialType = 'road';
            item.baseId = item.regionCode;
            item.needType = 'region';
            return item;
          });
          treeNode.dataRef.children = arr;
          let selectTreeRoadData = [...this.cameralList];
          this.setCameralList(selectTreeRoadData);
          resolve();
        }
      });
    },
    getRoadCameral(type) {
      let data = {
        provinceCode: '',
        regionCode: '',
        road: '',
        classify: this.classify,
      };
      let params = {
        dimensionType: type,
        data: data,
      };
      this.loading = true;
      /* this.cameralList = [];
      queryStaticDataList(params, (res) => {
        this.loading = false;
        this.cameralList = res.data;
      }); */
      this.setCameralList([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        this.setCameralList(res.data);
        // this.setChartRoadInfoData(res.data);
      });
    },
    getProvinceCameral(type) {
      let data = {
        cityCode: '',
        provinceCode: 410000,
        regionCode: 410000,
        road: '',
        classify: this.classify,
      };
      let params = {
        dimensionType: type,
        data: data,
      };
      this.loading = true;
      // this.cameralList = [];
      this.setCameralList([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        this.setCameralList(res.data);
        // this.setChartInfoData(res.data);
      });
    },
    // eslint-disable-next-line no-unused-vars
    getOrganizationCameral(type) {
      let data = {
        organizationId: '',
        provinceCode: 410000,
        regionCode: 410000,
        road: '',
        classify: this.classify,
      };
      let params = {
        dimensionType: 'province',
        data: data,
      };
      this.loading = true;
      /* this.cameralList = [];
      queryStaticDataList(params, (res) => {
        this.loading = false;
        this.cameralList = res.data;
      }); */
      this.setCameralList([]);
      queryStaticDataList(params, (res) => {
        this.loading = false;
        // this.cameralList = res.data;
        res.data = res.data.map((item) => {
          item.needType = 'region';
          item.regionCode = item.id;
          return item;
        });
        this.setCameralList(res.data);
        // this.setChartInfoData(res.data);
      });
    },
    onLoadCameraSelectData(treeNode) {
      // let expandData = this.treeNodeExpandPathDataCheck(treeNode);
      let vm = this;
      return new Promise((resolve) => {
        let type = treeNode.dataRef.type;
        //已有子节点，不重复加载
        /*  debugger; */
        if (type === 'roadBefore') {
          resolve();
          return;
        }

        if (!this.$g.treeDefinate) {
          if (this.$g.isDepart) {
            if (this.type === 'organization') {
              let expandData = this.treeNodeExpandPathDataCheck(treeNode);
              vm.getCameraSelectTreeData(type, expandData, (data) => {
                for (var i = 0, lens = data.length; i < lens; i++) {
                  data[i].provinceId = expandData;
                }

                treeNode.dataRef.children = data;
                if (vm.type === 'road') {
                  let selectTreeRoadData = [...vm.cameralList];

                  this.setCameralList(selectTreeRoadData);
                }
                if (vm.type === 'organization') {
                  let dataFormat = data.map((item) => {
                    if (item.type === 'organization') {
                      item.needType = 'organizationArea';
                      item.organizationId = item.id;
                    } else if (item.type === 'roadBefore') {
                      item.children.map((child) => {
                        child.needType = 'roadArea';
                        child.roadId = child.id;
                        return child;
                      });
                    }
                    return item;
                  });
                  treeNode.dataRef.children = dataFormat;
                  let selectTreeOrgData = [...vm.cameralList];
                  // this.$emit('showData', selectTreeOrgData);

                  this.setCameralList(selectTreeOrgData);
                }
                if (vm.type === 'province') {
                  let selectTreeRegionData = [...vm.cameralList];
                  // this.$emit('showData', selectTreeRegionData);

                  this.setCameralList(selectTreeRegionData);
                }
                resolve();
              });
            } else if (this.type === 'province') {
              let regionCode = treeNode.dataRef.regionCode;
              let gradeType = treeNode.dataRef.gradeType;
              let roadId = treeNode.dataRef.roadId;
              let regionCodes = treeNode.dataRef.regionCodes;
              if (gradeType < 4) {
                let params = {
                  type: gradeType,
                  regionCode,
                  regionCodes,
                };
                this.$api.home.getCameraByRegion(params).then((res) => {
                  if (res.code === 200) {
                    treeNode.dataRef.children = res.data.map((item) => {
                      if (gradeType === 2) {
                        item.title = item.regionNames;
                        item.needType = 'regions';
                      } else if (gradeType === 3) {
                        item.title = item.roadName;
                        item.needType = 'roadArea';
                      }
                      item.gradeType = params.type + 1;
                      item.isLeaf = false;
                      item.scopedSlots = { title: 'customtitle' };
                      item.check = false;
                      item.key = createGuid();
                      return item;
                    });
                    let selectTreeRoadData = [...vm.cameralList];
                    this.setCameralList(selectTreeRoadData);
                    resolve();
                  }
                });
              } else {
                let params = {
                  roadId,
                  regionCode,
                  regionCodes,
                };
                this.$api.home.getCameraListByRoad(params).then((res) => {
                  if (res.code === 200) {
                    treeNode.dataRef.children = res.data.map((item) => {
                      item.isLeaf = true;
                      item.type = 'camera';
                      item.needType = 'camera';
                      item.title = item.khPile;
                      item.check = false;
                      item.key = item.cameraNum;
                      item.scopedSlots = { title: 'customtitle' };
                      return item;
                    });
                    let selectTreeRoadData = [...vm.cameralList];
                    this.setCameralList(selectTreeRoadData);
                    resolve();
                  }
                });
              }
            } else if (this.type === 'road') {
              if (treeNode.dataRef.specialType === 'road') {
                let nval = treeNode.dataRef.regionCode;
                let parent = treeNode.$parent.dataRef;
                // console.error('haha', parent);
                queryStaticDataList(
                  {
                    dimensionType: 'camera',
                    data: {
                      provinceCode: parseInt(nval),
                      regionCode: parseInt(nval),
                      organizationType: '10',
                      roadId: parent.roadId,
                    },
                  },
                  (res) => {
                    let childrenData = res.data;
                    treeNode.dataRef.children = childrenData;
                    let selectTreeRoadData = [...vm.cameralList];
                    // this.$emit('showData', selectTreeRoadData);
                    this.setCameralList(selectTreeRoadData);
                    resolve();
                  }
                );
              } else {
                let roadId = treeNode.dataRef.roadId;
                this.clickRoads(roadId, treeNode, resolve);
              }
            }
          } else {
            let expandData = this.treeNodeExpandPathDataCheck(treeNode);
            vm.getCameraSelectTreeData(type, expandData, (data) => {
              for (var i = 0, lens = data.length; i < lens; i++) {
                data[i].provinceId = expandData;
              }

              treeNode.dataRef.children = data;
              if (vm.type === 'road') {
                let selectTreeRoadData = [...vm.cameralList];
                // this.$emit('showData', selectTreeRoadData);
                this.setCameralList(selectTreeRoadData);
              }
              if (vm.type === 'organization') {
                let selectTreeOrgData = [...vm.cameralList];
                // this.$emit('showData', selectTreeOrgData);
                this.setCameralList(selectTreeOrgData);
              }
              if (vm.type === 'province') {
                let selectTreeRegionData = [...vm.cameralList];
                // this.$emit('showData', selectTreeRegionData);
                this.setCameralList(selectTreeRegionData);
              }
              resolve();
            });
          }

          /* vm.getCameraSelectTreeData(type, expandData, (data) => {
            for (var i = 0, lens = data.length; i < lens; i++) {
              data[i].provinceId = expandData;
            }

            treeNode.dataRef.children = data;
            if (type === 'road') {
              let selectTreeRoadData = [...vm.cameralList];
              // this.$emit('showData', selectTreeRoadData);
              this.setCameralList(selectTreeRoadData);
            }
            if (type === 'organization') {
              let selectTreeOrgData = [...vm.cameralList];
              // this.$emit('showData', selectTreeOrgData);
              this.setCameralList(selectTreeOrgData);
            }
            if (type === 'province') {
              let selectTreeRegionData = [...vm.cameralList];
              // this.$emit('showData', selectTreeRegionData);
              this.setCameralList(selectTreeRegionData);
            }
            resolve();
          }); */
        } else {
          if (this.curPaType == 'roadPa') {
            let roadId = treeNode.dataRef.roadId;
            if (this.type === 'road') {
              // let roadId = treeNode.dataRef.roadId;
              let data = {
                gradeType: 2,
                params: {
                  regionCode: this.$g.regionCode,
                  roadId,
                },
              };
              // this.loading = true;
              // this.cameralListTree([]);
              this.$api.home.cameraTreeAccessByRoad(data).then((res) => {
                if (res.code === 200) {
                  treeNode.dataRef.children = treeDataReCheck(
                    res.data.info,
                    'camera'
                  );
                  let selectTreeRoadData = [...vm.cameralList];
                  this.setCameralList(selectTreeRoadData);
                  resolve();
                }
              });
            } else if (this.type === 'province') {
              /*  let gradeType =
              this.gradeType === 3 ? this.gradeType : this.gradeType + 1; */
              let gradeType = treeNode.dataRef.gradeType;
              let regionCode = treeNode.dataRef.regionCode;
              let data = {
                gradeType,
                params: {
                  regionCode,
                  roadId: gradeType === 3 ? roadId : '',
                },
              };
              this.$api.home.cameraTreeAccessByRegion(data).then((data) => {
                if (data.code === 200) {
                  /*  this.gradeType = data.data.hierarchy; */
                  treeNode.dataRef.children = treeDataReCheck(
                    data.data.info,
                    gradeType === 2 ? 'provinceRoad' : 'camera'
                  );
                  let selectTreeRoadData = [...vm.cameralList];
                  this.setCameralList(selectTreeRoadData);
                  resolve();
                }
              });
            } else if (this.type === 'organization') {
              let organizationId = treeNode.dataRef.organizationId;
              let data = {
                organizationId,
              };
              this.$api.home.cameraTreeByOrgInfo(data).then((res) => {
                if (res.code === 200) {
                  treeNode.dataRef.children = treeDataReCheck(
                    res.data,
                    'camera'
                  );
                  let selectTreeRoadData = [...vm.cameralList];
                  this.setCameralList(selectTreeRoadData);
                  resolve();
                }
              });
            }
          } else {
            let organizationId = treeNode.dataRef.organizationId;
            let data = {
              organizationId,
              regionCode: this.$g.regionCode,
            };
            this.$api.aiCenterY
              .getTreeAccessOffHighwayCamera(data)
              .then((res) => {
                if (res.code === 200) {
                  treeNode.dataRef.children = treeDataReCheck(
                    res.data,
                    'camera',
                    this.typePa
                  );
                  let selectTreeRoadData = [...vm.cameralList];
                  this.setCameralList(selectTreeRoadData);
                  resolve();
                }
              });
          }
        }
      });
    },
    getCameraSelectTreeData(currtype, expandData, callback) {
      let childrenData = [],
        type = '';
      let path = this.cameraTreeDataPath[this.type];
      type = path[path.indexOf(currtype) + 1];

      if (type === 'road' && expandData.provinceCode) {
        expandData.groupbyRoadType = false;
      }
      this.lastKey = type;
      queryStaticDataList(
        {
          dimensionType: type,
          data: expandData,
        },
        (res) => {
          childrenData = res.data;

          if (typeof callback === 'function') {
            callback(childrenData);
          }
        }
      );
    },
    /**
     * 查询节点父节点信息
     * @param node
     */
    treeNodeExpandPathDataCheck(node) {
      let path = this.cameraTreeDataPath[this.type],
        newKsys = {};
      let getParentKeyArray = (node) => {
        let keys = [],
          newNode = node;
        for (let i = 0; i < 10; i++) {
          let dataref = newNode.$parent.dataRef;
          if (dataref) {
            newNode = newNode.$parent;
            //console.info('getParent ', dataref);
            if (dataref.type !== 'roadBefore') keys.push(dataref.baseId);
          }
        }
        // 倒序
        return keys.reverse();
      };
      let pathValAr = getParentKeyArray(node);
      if (node.dataRef.type !== 'roadBefore')
        pathValAr.push(node.dataRef.baseId);
      path.forEach((vo, idx) => {
        let nval = pathValAr[idx];
        if (!nval) {
          return;
        }
        switch (vo) {
          case 'province':
            newKsys.provinceCode = parseInt(nval);
            newKsys.regionCode = parseInt(nval);
            // newKsys.organizationType = '10';fix sxy
            break;
          case 'city':
            newKsys.cityCode = parseInt(nval);
            break;
          case 'organization':
            newKsys.organizationType = '20';
            newKsys.organizationId = nval;
            break;
          case 'road':
            newKsys.organizationType = '20';
            newKsys.road = nval;
            break;
        }
      });
      newKsys.classify = this.classify;
      return newKsys;
    },
    /**
     * 树节点点击
     * @param selectedKeys
     * @param e
     */
    treeNodeSelect(selectedKeys, e) {
      let dataRef = e.node.dataRef;
      //设置点击展开收缩节点
      let selectedKey = e.node.dataRef.key;
      if (!e.node.expanded) {
        this.expandedTreeKeys = [...this.expandedTreeKeys, selectedKey];
      } else {
        this.expandedTreeKeys = this.expandedTreeKeys.filter(
          (item) => item !== selectedKey
        );
      }
      //设置check状态

      let copyCamera = JSON.parse(JSON.stringify(this.cameralList));
      e.node.dataRef.check = e.node.dataRef.check ? false : true;
      let cameraList = treeTrackCameraMulCheck(copyCamera, e.node.dataRef);

      this.setCameralList(cameraList);

      // let leafType = dataRef.type;
      if (dataRef.type !== 'camera') {
        this.onLoadCameraSelectData(e.node);
      }
      // console.log('ha', dataRef);
      this.$emit('getCamera', dataRef);

      /* if (leafType === 'camera') {
        this.$emit('getCamera', dataRef);
      } fix之前只筛选摄像机*/
    },
    extraObj(leafType, dataRef) {
      let obj = {};
      if (leafType === 'road') {
        obj.roadCode = dataRef.id;
      } else if (leafType === 'province') {
        obj.regionId = dataRef.id;
      } else if (leafType === 'organization') {
        obj.organizationId = dataRef.id;
      }
      return obj;
    },
    handlePlay(title) {
      console.error(title);
    },
    //新的
    showComNewYunNanHandle(item) {
      let type = item.type;
      this.type = type;
      this.currentCom = item.id;
      this.curChildItem = item; //新增
      this.obj = {
        currPage: 1,
        pageSize: 10,
      };
      if (this.$g.treeDefinate) {
        if (item.id === 0 || item.id === 1 || item.id === 2) {
          this.currentOrganizationId = '';
          this.selectYunData(type);
        } else if (item.id === 3) {
          this.currentType = '';
          this.currentOrganizationId = '9901004075';
          this.getYunParentData('9901004075');
        } else if (item.id === 4) {
          this.currentType = '';
          this.currentOrganizationId = '9901004074';
          this.getYunParentData('9901004074');
        } else if (item.id === 5) {
          this.currentType = '';
          this.currentOrganizationId = '9901004076';
          this.getYunParentData('9901004076');
        } else if (item.id === 6) {
          this.currentType = '';
          this.currentOrganizationId = '9901004077';
          this.getYunParentData('9901004077');
        } else {
          this.currentType = '';
          this.currentOrganizationId = '0000'; //不存在的organizationId
          this.setCameralList([]);
        }
      }
      this.$emit('getCamera', {
        needType: 'none',
        organizationId: this.currentOrganizationId,
      });
      this.expandedKeys = [];
    },
    showComHandle(item) {
      let type = item.type;
      this.type = type;
      this.currentCom = item.id;
      this.obj = {
        currPage: 1,
        pageSize: 10,
      };
      // this.setClassify('');fixsxy
      // this.setComType(type);fixsxy
      if (this.$g.treeDefinate) {
        this.selectYunData(type);
      } else {
        this.selectData(type);
      }
      this.$emit('getCamera', { needType: 'none' });
      this.expandedKeys = [];
      // this.setTreeStru(null);
    },
  },
};
</script>

<style scoped>
.icon-style {
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  transform-origin: center center;
}
.online {
  background: #5cc189;
}
.offline {
  background: #a7b1be;
}
.error {
  background: #ef5844;
}
.fl-bg.current {
  background: #424e65;
  padding: 0 10px;
}
/* .tree-node >>> .ant-tree-node-content-wrapper-open,
.tree-node >>> li .ant-tree-node-selected,
.tree-node >>> .ant-tree-node-content-wrapper-open:hover,
.tree-node >>> li .ant-tree-node-selected:hover {
  background: #424e65;
} */
</style>
