var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('div',{staticClass:"flex flex-end"},[(_vm.curTypeIndex === 0 && _vm.$g.isDepart)?_c('home-left-menu',{ref:"homeChildMenu",staticClass:"wd50",attrs:{"list":_vm.subList},on:{"showCom":_vm.showComHandle}}):(_vm.$g.treeDefinate && _vm.noCollectAndGroup)?_c('list-menu',{ref:"homeChildMenu",attrs:{"list":_vm.getChildList},on:{"showCom":_vm.showComNewYunNanHandle}}):_vm._e()],1),(_vm.cameralList.length > 0)?_c('a-tree',{attrs:{"load-data":_vm.onLoadCameraSelectData,"tree-data":_vm.cameralList,"expandedKeys":_vm.expandedTreeKeys},on:{"update:expandedKeys":function($event){_vm.expandedTreeKeys=$event},"update:expanded-keys":function($event){_vm.expandedTreeKeys=$event},"select":_vm.treeNodeSelect},scopedSlots:_vm._u([{key:"customtitle",fn:function(ref){
var title = ref.title;
var online = ref.online;
var total = ref.total;
var type = ref.type;
var onlineStatus = ref.onlineStatus;
var check = ref.check;
return [[(type === "camera")?_c('span',{staticClass:"flex items-center"},[_c('span',{class:{
                'm-r-xs icon-style': true,
                online: onlineStatus === '1',
                offline: onlineStatus === '0',
                error: onlineStatus === '2',
              }}),_vm._v(_vm._s(title))]):_c('div',{class:[
              'flex fl-bg',
              { 'not-cursor': _vm.cameraLoading, current: check } ]},[_c('span',{staticClass:"m-r-xs"},[_vm._v(_vm._s(title))]),_c('span',{staticClass:"f12"},[_vm._v(" ("),_c('span',{staticClass:"text-lightblue"},[_vm._v(_vm._s(online))]),_c('span',{staticClass:"m-l-xs m-r-xs"},[_vm._v("/")]),_vm._v(_vm._s(total)+") ")])])]]}}],null,false,477938423)}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"full-width flex items-center justify-center"},[_c('a-spin')],1):_vm._e(),(_vm.cameralList.length <= 0 && !_vm.loading)?_c('div',{staticClass:"text-lightblue text-center p-h-sm"},[_vm._v(" 空空如也~ ")]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }