<template>
  <div class="full-width full-height">
    <!--  <home-left-menu
        :list="yunNan"
        @showCom="showComHandleYunan"
        v-if="$g.treeDefinate"
      /> -->
    <home-left-menu
      :list="subList"
      :cameraRouteFlag="true"
      @showCom="showComHandle"
      class="m-b-sm"
    />
    <vue-scroll :ops="$root.scrollOpsXY" style="height:calc(100% - 60px);">
      <!-- <div class="scroll full-height"> -->
      <template>
        <template v-if="mode === 0">
          <cameral-group-com
            v-if="curIndex === 2 && !noCollectAndGroup"
            :ope="false"
            :folderType="true"
            @getCamera="getCameraHandle"
          />
          <template v-else-if="curIndex === 1 && !noCollectAndGroup">
            <collect-com :ope="false" :mode="0" @getCamera="getCameraHandle" />
          </template>
          <!-- <div
            class="flex items-center pointer"
            v-else-if="curIndex === 1"
            @click="collectHandle()"
          ></div> -->
          <!-- 以前 <tree-list-com  v-else-if="curIndex === 0" /> -->
          <tree-list-com
            ref="treeCom"
            :curItem="curItem"
            :curTypeIndex="curIndex"
            :noCollectAndGroup="noCollectAndGroup"
            @getCamera="getCameraHandle"
            v-else
          />
        </template>
        <template v-else>
          <cameral-group-com
            v-if="curIndex === 2 && !noCollectAndGroup"
            :ope="false"
            :noPlay="true"
            @getCamera="getCameraHandle"
          />
          <template v-else-if="curIndex === 1 && !noCollectAndGroup">
            <collect-com :ope="false" :mode="1" @getCamera="getCameraHandle" />
          </template>

          <tree-list-com-mode
            ref="treeCom"
            :curItem="curItem"
            :curTypeIndex="curIndex"
            @getCamera="getCameraHandle"
            v-else
          ></tree-list-com-mode>
        </template>
      </template>
     <!--  </div> -->
    </vue-scroll>
  </div>
</template>

<script>
import HomeLeftMenu from './LeftMenu';
import TreeListCom from './TreeListCom';
import CameralGroupCom from './CameraGroupNew';
// import CameralGroupMode from './CameralGroupMode';
import CollectCom from './CollectComFavoritRoute';
import TreeListComMode from './TreeListComMode';
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  name: 'CameralRoute',
  props: {
    cameraTabs: {
      type: String,
      default: '',
    },
    mode: {
      type: Number,
      default: 0,
    },
    resource: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subList: [
        {
          id: 0,
          name: '树状调阅',
        },
        {
          id: 1,
          name: '我的收藏',
        },
        {
          id: 2,
          name: '摄像机组',
        },
      ],
      curItem: {},
      curIndex: 0,
      curTreeIndex: 0,
      noCollectAndGroup: false,
    };
  },
  components: {
    HomeLeftMenu,
    TreeListCom,
    CameralGroupCom,
    // CameralGroupMode,
    CollectCom,
    TreeListComMode,
  },
  created() {
    this.getCameraGroup();
  },
  mounted() {
    /*  if (!this.showCameralGroup) {
      this.subList.pop();
    } */

    // console.error('haha', this.cameraTabs);
    if (this.cameraTabs === 'collect') {
      this.subList = [
        {
          id: 1,
          name: '我的收藏',
        },
      ];
      this.curIndex = 1;
      this.noCollectAndGroup = false;
    } else {
      if (!this.$g.treeDefinate) {
        this.subList = [
          {
            id: 0,
            name: '树状调阅',
          },
          {
            id: 1,
            name: '我的收藏',
          },
          {
            id: 2,
            name: '摄像机组',
          },
        ];
        this.noCollectAndGroup = false;
      } else {
        this.subList = [
          {
            id: 0,
            name: '公路',
            type: 'roadPa',
            organizationId: '',
          },
          {
            id: 1,
            name: '其他',
            type: 'roadOther',
            organizationId: '9901004075',
          },
          /*  {
            id: 3,
            name: '客运站',
            type: 'noRoadPa',
            organizationId: '9901004074',
          },
          {
            id: 4,
            name: '轨道交通',
            type: 'railTraffic',
            organizationId: '9901004075',
          },
          {
            id: 5,
            name: '港口码头',
            type: 'noRoadPa',
            organizationId: '9901004076',
          },
          {
            id: 6,
            name: '口岸',
            type: 'noRoadPa',
            organizationId: '9901004077',
          }, */
          /* {
            id: 1,
            name: '我的收藏',
            organizationId: '',
          },
          {
            id: 2,
            name: '摄像机组',
            organizationId: '',
          }, */
        ];
        this.noCollectAndGroup = true;
      }

      this.curIndex = 0;
      this.curItem = this.subList[this.curIndex];
    }
  },
  computed: {
    ...mapState({
      cameraAllList: (state) => state.collect.cameraAllList,
      cameraTab: (state) => state.collect.cameraTab,
      cameraGroupList: (state) => state.collect.cameraGroupList,
      cameraGroupLists: (state) => state.resource.cameraGroupLists,
      groupId: (state) => state.collect.groupId,
    }),
  },
  watch: {},
  methods: {
    ...mapMutations(['setCameraAllList', 'setCurrentComIndex', 'setGroupId']),
    ...mapActions(['getCameraListByCondition', 'getCameraGroup']),
    showComHandle(item) {
      this.curIndex = item.id;
      this.curItem = item;
      // this.setCurrentComIndex(this.curIndex);fixsxy
      /*  if (this.$g.treeDefinate && this.curIndex !== 1 && this.curIndex !== 2) {
        this.$nextTick(() => {
          this.$refs.treeCom.showParentComHandle(item);
        });
      } */
      /*  let id = '';
      //设置默认组id
      // if (this.curIndex === 2) {
      //   if (this.resource) {
      //     //fixsxy !this.resource摄像机组前
      //     id = this.cameraGroupLists[0].id;
      //   } else { 
        for (let i in this.cameraGroupList) {
          let child = this.cameraGroupList[i].children;
          if (child && child.length > 0) {
            id = child[0].id;
            break;
          }
        }
         // }  

        // console.error('hahah', id);
        this.setGroupId(this.groupId ? this.groupId : id);
      } */
      //传递给父组件
      this.$emit('routeHandle', this.curIndex);
      /*  if (this.mode === 1) { */
      this.$emit('postCamera', {
        needType: 'none',
        organizationId: item.organizationId,
      });
      /* } */
    },
    showComSubHandle(item) {
      this.curTreeIndex = item.id;
    },
    getCameraHandle(camera) {
      this.$emit('postCamera', camera);
    },
    /* collectHandle() {
      let obj = {};
      if (this.$g.isDepart) {
        obj = {
          currPage: 1,
          pageSize: 10,
          cameraFavoriteFlag: 1,
        };
      } else {
        obj = {
          currPage: 0,
          pageSize: 0,
          cameraFavoriteFlag: 1,
        };
      }

      this.setCameraAllList([]);
      this.getCameraListByCondition(obj);
    }, */
  },
};
</script>

<style scoped>
.scroll{
  overflow-x: auto;
}
</style>
