<template>
  <div class="collect-com full-height" style="padding-bottom:50px;">
   <!--  <vue-scroll :ops="$root.scrollOpsY" style="height: 100%"> -->
      <div
        class="folder m-b-sm pointer"
        v-for="folder in homeFolderCameraFormat"
        :key="folder.folderId"
      >
        <div
          class="folder-hd flex justify-between items-center  f15 m-b-sm"
          @click="expandFolder(folder)"
        >
          <div class="text-white flex"
          draggable="true"
          @dragstart="dragstartFolder($event, folder)"
          @dragend="dragendFolder"
          >
            <span class="weight flex items-center flex-one white-space" :title="folder.folderName"
              ><!-- <a-icon type="folder" class="m-r-xs" /> --><img
                src="../assets/resource/collect-folder.png"
                width="20"
                class="m-r-xs"
              />{{  folder.folderName }}</span
            >({{ folder.cameraNumber }})
          </div>
        </div>
        <template
          v-if="folder.show && folder.cameraList && folder.cameraList.length"
        >
          <div
            class="flex items-center m-b-sm p-w-sm text-white"
            v-for="item in folder.cameraList"
            :key="item.cameraId"
            :title="item.cameraNames"
          >
            <span
              :class="[
                'icon-style  m-r-xs',
                {
                  online: item.cameraStatus === 1,
                  offline: item.cameraStatus === 0,
                  error: item.cameraStatus === 2,
                  yunOffline: item.cameraStatus === 0 && $g.treeDefinate,
                },
              ]"
            ></span>
            <span
              class="flex-one ellipsis pointer"
              @click.stop.prevent="selectShot(item)"
              ><!-- {{ item.cameraName }}
                {{ item.derection | getDirectionArrow }} -->{{
                item.cameraNames
              }}</span
            >
          </div>
        </template>
      </div>
    <!-- </vue-scroll> -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { DIRECTOINARROW } from '@/tools';
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'CollectComFavoritRoute',
  props: {
    ope: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      turnVisible: false,
      favoritVisible: false,
      isEdit: false,
      folderId: '',
      folderName: '',
    };
  },
  computed: {
    ...mapState({
      screenShotSearchParams: (state) => state.resource.screenShotSearchParams,
      homeFolderCamera: (state) => state.home.homeFolderCamera,
      homeFolder: (state) => state.home.homeFolder,
    }),
    homeFolderCameraFormat() {
      return this.mode == 0 ? this.homeFolder : this.homeFolderCamera;
    },
  },
  watch: {},
  components: {},
  mounted() {
    if (this.mode === 0) {
      this.getHomeFolder();
    } else {
      this.getHomeDefaultCamera();
    }
  },
  methods: {
    ...mapMutations([
      'setScreenShotSearchParams',
      'setSelectCameraList',
      'setCameraAllList',
    ]),
    ...mapActions([
      'getScreenShotInfo',
      'getHomeDefaultCamera',
      'getHomeFolderCamera',
      'getHomeFolder',
      'getCameraListByCondition',
    ]),
     //文件夹拖拽
    dragstartFolder(event, folder) {
      let data = {
        folder: folder.folderId,
      };
      this.getHomeFolderCamera(data).then((res) => {
        const { code, data } = res;
        if (code === CODE_OK) {
          this.$emit('dragCameraList', data);
        }
      });
    },
    //文件夹拖拽结束
    dragendFolder(){
      // event.dataTransfer.clearData();
      this.$emit('dragendCameraList');
    },
    subName(name) {
      return name.length > 10 ? name.slice(0, 10) + '...' : name;
    },

    getTitle(item) {
      return `桩号:${item.kmHmPile}.方向:${
        DIRECTOINARROW[item.derection]
      },兴趣点名称:${item.poiName}`;
    },

    selectShot(item) {
      /* let data = {
        ...this.screenShotSearchParams,
        ...{ cameraId: item.cameraId },
      };
      this.setScreenShotSearchParams(data);
      this.getScreenShotInfo(data); */
      item.type = 'camera';
      this.$emit('getCamera', item);
    },

    expandFolder(folder) {
      if (this.mode == 0) {
        this.collectHandle(folder);
      } else {
        if (!folder.show) {
          let data = {
            folder: folder.folderId,
          };
          this.getHomeFolderCamera(data).then(() => {
            folder.show = !folder.show;
          });
          return;
        }
        folder.show = !folder.show;
      }
    },
    collectHandle(folder) {
      /* let obj = {};
      if (this.$g.isDepart) {
        obj = {
          currPage: 1,
          pageSize: 10,
          cameraFavoriteFlag: 1,
        };
      } else {
        obj = {
          currPage: 0,
          pageSize: 0,
          cameraFavoriteFlag: 1,
        };
      }

      this.setCameraAllList([]);
      this.getCameraListByCondition(obj); */
      this.$emit('getCamera', {
        needType: 'collect',
        collectId: folder.folderId,
      });
    },
  },
};
</script>

<style scoped>
.title-con {
  border-bottom: 1px dashed rgba(40, 69, 106, 1);
}
.white-space {
  white-space: nowrap;
}
</style>
