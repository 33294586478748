var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tree-node"},[_c('div',{staticClass:"flex flex-end"},[(_vm.curTypeIndex === 0 && _vm.$g.isDepart)?_c('home-left-menu',{ref:"homeChildMenu",staticClass:"wd50",attrs:{"list":_vm.subList},on:{"showCom":_vm.showComHandle}}):(_vm.$g.treeDefinate)?_c('list-menu',{ref:"homeChildMenu",attrs:{"list":_vm.getChildList},on:{"showCom":_vm.showComNewYunNanHandle}}):_vm._e()],1),(_vm.cameralList.length > 0)?_c('a-tree',{attrs:{"load-data":_vm.onLoadCameraSelectData,"tree-data":_vm.cameralList,"expandedKeys":_vm.expandedTreeKeys},on:{"select":_vm.treeNodeSelect,"expand":_vm.onExpand,"update:expandedKeys":function($event){_vm.expandedTreeKeys=$event},"update:expanded-keys":function($event){_vm.expandedTreeKeys=$event}},scopedSlots:_vm._u([{key:"customtitle",fn:function(ref){
      var title = ref.title;
      var online = ref.online;
      var total = ref.total;
      var type = ref.type;
      var onlineStatus = ref.onlineStatus;
      var khPile = ref.khPile;
      var poiName = ref.poiName;
      var derection = ref.derection;
      var cameraName = ref.cameraName;
      var check = ref.check;
return [[(type === "camera" && !_vm.$g.treeDefinate)?_c('div',{staticClass:"flex items-center",staticStyle:{"width":"240px"},attrs:{"title":_vm.getTitle(khPile, poiName, derection)}},[_c('span',{class:{
              'm-r-xs icon-style': true,
              online: String(onlineStatus) === '1',
              offline: String(onlineStatus) === '0',
              error: String(onlineStatus) === '2',
            }}),_c('span',{staticClass:"ellipsis full-width fl-bg",class:{ current: check }},[_vm._v(" "+_vm._s(title)+" "+_vm._s(poiName)+" "+_vm._s(_vm._f("getDirectionArrow")(derection)))])]):(type === "camera" && _vm.$g.treeDefinate)?_c('div',{staticClass:"flex items-center",staticStyle:{"width":"200px"}},[_c('span',{class:{
              'm-r-xs icon-style': true,
              online: String(onlineStatus) === '1',
              yunOffline: String(onlineStatus) === '0',
              error: String(onlineStatus) === '2',
            }}),_c('div',{staticClass:"fl-bg",class:{ current: check },staticStyle:{"width":"180px"}},[_c('span',{staticClass:"ellipsis full-width block"},[_vm._v(" "+_vm._s(khPile))]),_c('span',{staticClass:"ellipsis full-width f12 block ellipsis",attrs:{"title":cameraName}},[_vm._v(_vm._s(cameraName))])])]):_c('div',{staticClass:"flex fl-bg",class:{ current: check }},[_c('span',{staticClass:"m-r-xs"},[_vm._v(_vm._s(title))]),_c('span',{staticClass:"f12"},[_vm._v(" ("),_c('span',{staticClass:"text-lightblue"},[_vm._v(_vm._s(online))]),_c('span',{staticClass:"m-l-xs m-r-xs"},[_vm._v("/")]),_vm._v(_vm._s(total)+") ")])])]]}}],null,false,3442554601)}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"full-width flex items-center justify-center"},[_c('a-spin')],1):_vm._e(),(_vm.cameralList.length <= 0 && !_vm.loading)?_c('div',{staticClass:"text-lightblue text-center p-h-sm"},[_vm._v(" 空空如也~ ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }